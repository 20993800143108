export default {
  props: ['show_seq', 'seq_list', 'seq_url', 'father_item', 'seq_tit'],
  computed: {
    show() {
      return this.show_seq;
    }
  },
  data() {
    return {
      seq_item: {}
    };
  },
  methods: {
    open() {
      this.seq_item = this.father_item;
    },
    closePicker() {
      this.$emit('update:show_seq', false);
    },
    checkSeq(item) {
      this.seq_item = item;
    },
    getCheckSeq() {
      const item = this.seq_item && JSON.stringify(this.seq_item) != "{}" ? this.seq_item : this.seq_list[0];
      this.$emit('getCheckSeq', item);
      this.closePicker();
    }
  }
};