import Error from "@@/common/Error.vue";
import lowerdev from '@/service/lowerdev';
import redirect from '@/service/redirect';
import { env, domPixelRatio } from 'md-base-tools/env';
import { mapState, mapActions } from "vuex";
import { getActivityList, getMergerList, getHashAddress, cbaMergerByHash, getUIdata } from "@/api/index";
import { throttle } from "@/utils/tools";
import AddressEdit from "@@/digital/activity/address_edit.vue";
import AddressShow from "@@/digital/activity/address_show.vue";
import CompLoading from "@@/digital/activity/loading.vue";
import CompSeqList from "@@/digital/activity/seq_list.vue";
import { goToLogin } from "@/utils/user";
import { showFailToast, showSuccessToast } from "vant";
const __default__ = {
  components: {
    Error,
    AddressEdit,
    AddressShow,
    CompLoading,
    CompSeqList
  },
  computed: {
    ...mapState({
      ifLogin: state => state.user.ifLogin,
      // 是否登录
      userId: state => state.user.userId,
      token: state => state.user.token
    }),
    isPhone() {
      return env.isModianIos() || env.isModianAndroid();
    },
    ratio() {
      return parseInt(domPixelRatio()) || 1;
    }
  },
  data() {
    return {
      showError: true,
      showMessage: '活动不存在！',
      activityData: {},
      activity_id: '',
      //活动id
      isLogin: false,
      status: '0',
      // 合成未开始
      is_merger: 0,
      // 是否可合成 0、不能合成 1、允许合成
      // pro_status: 1, // 项目是否结束
      nfts: [],
      // 获取活动藏品信息
      times: 0,
      // 开奖倒计时
      times_show: "",
      timer: null,
      loading: false,
      show: false,
      showEdit: false,
      showPicker: false,
      open_prize_status: 0,
      // 是否开奖 0、未开奖 1、已开奖 2、无开奖
      prize_activity_id: '',
      // 有开奖是，查询开奖信息所需参数
      merger_list: [],
      time_seconds: '',
      // 时间
      address_info: {
        address_name: '',
        address_mobile: "",
        province: '',
        address: ''
      },
      loading_time: 0,
      timer_prize: null,
      // 序列号
      show_seq: false,
      seq_url: '',
      // seq: '', 
      seq_item: {},
      //选中序号
      seq_index: '',
      seq_list: [],
      //序列号列表
      seq_tit: '',
      // 序列号
      link_url: '',
      // 收货地址
      stock_hash: '',
      relation_id: '',
      address_type: 'add',
      // 收获地址类型
      fill_end_time: '',
      describe: ''
    };
  },
  beforeMount() {
    if (env.isWxApp()) {
      lowerdev.dynamicScript("https://res.wx.qq.com/open/js/jweixin-1.3.2.js");
    }
    const query = this.$route.query;
    if (query['MDUSERTOKEN']) {
      localStorage.setItem("MDUSERTOKEN", query['MDUSERTOKEN']);
    }
  },
  mounted() {
    const ui_id = this.$route.params.id ? this.$route.params.id.replace('.html', '') : '';
    this.getUI(ui_id);
  },
  unmounted() {
    clearInterval(this.timer);
    clearInterval(this.timer_prize);
  },
  methods: {
    ...mapActions({
      getAddressList: "getAddressList"
    }),
    // 获取UI数据
    async getUI(id) {
      var {
        status,
        data,
        message
      } = await getUIdata({
        activity_id: id
      });
      if (status != 0) {
        this.showError = true;
        this.showMessage = message;
        return;
      }
      this.showError = false;
      this.activityData = JSON.parse(data);
      this.activity_id = this.activityData.activity_id;
      this.prize_activity_id = this.activityData.prize_activity_id || '';
      this.link_url = this.activityData.link_url || '';
      document.title = this.activityData.title;
      this.checkLogin();
      this.getAddressList();
    },
    async getActivity() {
      var res = await getActivityList({
        activity_id: this.activity_id
      });
      if (res.status == 0) {
        this.status = res.data.status;
        this.is_merger = res.data.is_merger;
        this.nfts = res.data.nfts;
        // this.pro_status = res.data.pro_status
        this.times = res.data.time_seconds;
        if (res.data.status == 0) {
          this.timer = setInterval(() => {
            this.times--;
            this.times_show = this.getDuration(this.times);
            if (this.times <= 0 && res.data.status == 0) {
              clearInterval(this.timer);
              window.location.reload();
            }
          }, 1000);
        }
        if (this.ifLogin && this.status != 0) {
          this.getMerger();
        }
      }
    },
    async getMerger() {
      let option = {
        merge_activity_id: this.activity_id
      };
      if (this.open_prize_status !== 2) {
        option.prize_activity_id = this.prize_activity_id;
      }
      const {
        status,
        data
      } = await getMergerList(option);
      if (status == 0) {
        this.merger_list = data.list;
        this.open_prize_status = data.open_prize_status;
        if (data.total > 0 && data.open_prize_status == 0 && data.time_seconds > 0) {
          this.timer_prize = setInterval(() => {
            data.time_seconds--;
            this.time_seconds = this.getDuration(data.time_seconds);
            if (data.time_seconds <= 0) {
              clearInterval(this.timer_prize);
              this.getMerger();
            }
          }, 1000);
        }
      }
    },
    fnThrottle(fn, delay, atleast) {
      let timer = null;
      let previous = null;
      return function () {
        let now = +new Date();
        if (!previous) previous = now;
        if (atleast && now - previous > atleast) {
          fn();
          previous = now;
          clearTimeout(timer);
        } else {
          clearTimeout(timer);
          timer = setTimeout(function () {
            fn();
            previous = null;
          }, delay);
        }
      };
    },
    // 开始合成
    goMerge: throttle(async function () {
      const that = this;
      this.loading_time = 0;
      this.loading = true;
      let num = Math.floor(Math.random() * 5) + 1;
      let res = {};
      await Promise.all([new Promise(resolve => {
        const timer = setInterval(async () => {
          if (that.loading_time >= 100) {
            resolve(true);
            clearInterval(timer);
          } else {
            that.loading_time += 1;
          }
        }, 100);
      }), new Promise(resolve => {
        setTimeout(async () => {
          let arr = this.nfts.map(item => item.seq_item ? item.seq_item.stock_hash : item.seq_hash_list[0].stock_hash);
          res = await cbaMergerByHash({
            activity_id: this.activity_id,
            consume_hash_list: JSON.stringify(arr)
          });
          resolve(true);
        }, num * 1000);
      })]);
      if (that.loading_time >= 99) {
        that.loading = false;
        if (res.status == 0) {
          if (res.data) {
            showSuccessToast('合成成功');
          } else {
            showFailToast(res.message);
          }
        } else {
          showFailToast(res.message);
        }
        that.getActivity();
      }
    }, 1000),
    // 查看收货地址
    async showPopup(stock_hash) {
      this.show = true;
      const {
        status,
        data
      } = await getHashAddress({
        stock_hash: stock_hash
      });
      if (status == 0) {
        this.address_info = data.address;
      }
    },
    // 填写收货地址
    // showPopupAdd(stock_hash, relation_id) {
    //     this.stock_hash = stock_hash
    //     this.relation_id = relation_id
    //     this.showEdit = true
    // },
    // 填写收货地址 修改收货地址
    async showPopupEdit(item, type) {
      this.stock_hash = item.stock_hash;
      this.relation_id = item.relation_id;
      this.fill_end_time = item.fill_end_time || '';
      this.describe = item.describe || '';
      this.address_type = type;
      if (type == 'edit' || type == 'disabled') {
        const {
          status,
          data
        } = await getHashAddress({
          stock_hash: item.stock_hash,
          prize_activity_id: this.prize_activity_id
        });
        if (status == 0) {
          this.address_info = Object.assign({}, data.address);
          console.log('this.address_info', this.address_info);
        }
      }
      this.showEdit = true;
    },
    // 查看序列号集合
    goToChoseSeq(list, url, index, title) {
      if (!(list && list.length)) return;
      this.seq_list = list;
      this.seq_url = url;
      this.seq_index = index;
      this.seq_item = this.nfts[index].seq_item ? this.nfts[index].seq_item : list[0];
      this.seq_tit = title;
      this.show_seq = true;
    },
    getCheckSeq(item) {
      if (item) {
        this.nfts[this.seq_index].seq_item = item;
      }
    },
    getDuration(second) {
      var duration;
      var days = Math.floor(second / 86400);
      var hours = Math.floor(second % 86400 / 3600);
      var minutes = Math.floor(second % 86400 % 3600 / 60);
      var seconds = Math.floor(second % 86400 % 3600 % 60);
      if (days > 0) duration = days + "天" + hours + "小时" + minutes + "分" + seconds + "秒";else if (hours > 0) duration = hours + "小时" + minutes + "分" + seconds + "秒";else if (minutes > 0) duration = minutes + "分" + seconds + "秒";else if (seconds > 0) duration = seconds + "秒";
      return duration;
    },
    // 项目未结束-跳转至项目详情
    /* eslint-disable */
    goToLink() {
      if (this.isPhone) {
        graft.app({
          functionName: "openController",
          functionParams: this.link_url
        });
      } else if (env.isWxApp()) {
        if (this.link_url.includes('project')) {
          let arr = this.link_url.split('/');
          let id = arr[arr.length - 1].replace('.html', '');
          wx.miniProgram.navigateTo({
            url: `/pages/project-detail/project-detail?pro_id=${id}`
          });
        } else if (this.link_url.includes('product')) {
          wx.miniProgram.navigateTo({
            url: `/pages/content/content?url=${this.link_url}`
          });
        }
      } else {
        location.href = this.link_url;
      }
    },
    // 检测登录信息
    checkLogin() {
      if (this.isPhone) {
        graft.app({
          functionName: "onGetUserInfo",
          functionParams: {},
          callBack: e => {
            var token = '';
            var user_id = '';
            if (e.errorCode == 0) {
              user_id = e.data.user_id ? e.data.user_id : e.data.userId;
              token = e.data.token;
              let icon = e.data.icon || "";
              localStorage.setItem("MDUSERTOKEN", user_id + "#" + token);
              localStorage.setItem("MDUSERINFO", icon);
              this.isLogin = true;
            } else {
              user_id = '';
              token = '';
              localStorage.removeItem("MDUSERTOKEN");
              localStorage.removeItem("MDUSERINFO");
              this.isLogin = false;
            }
            setTimeout(() => {
              this.getActivity();
            });
          },
          webFun: e => {
            redirect.to("login");
          }
        });
      } else if (env.isWxApp()) {
        const query = this.$route.query;
        if (query['MDUSERTOKEN'] && query['MDUSERTOKEN'].length > 3) {
          this.isLogin = true;
        } else {
          this.isLogin = false;
        }
        this.getActivity();
      } else {
        this.isLogin = this.ifLogin;
        this.getActivity();
      }
    },
    goToLogin() {
      goToLogin();
    }
  }
};
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
  _useCssVars(_ctx => ({
    "7efb8fd6": _ctx.activityData.bg_color || "#5C43EF"
  }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__ ? (props, ctx) => {
  __injectCSSVars__();
  return __setup__(props, ctx);
} : __injectCSSVars__;
export default __default__;